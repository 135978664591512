import React from "react";

import { FullPage, Slide } from "react-full-page";
import Home from "./Home";
import Navbar from "../Navbar";
import About from "./About";
import "../../scss/sections.scss";
import Experience from "./Experience";
import Contact from "./Contact";
import ProjectCard from "./Cards/ProjectCard";

// Images
import ascoldata_img from "../../images/ascoldata.webp";
import evcs_img from "../../images/evcs.webp";
import tweet_sa_img from "../../images/tweet_sa.webp";
import jmm_img from "../../images/jmm.webp";
import air_meddigital_img from "../../images/air_meddigital.webp";
import paintmix_img from "../../images/paintmix.webp";
import { isMobile } from "react-device-detect";

class CustomControls extends React.Component {
  static defaultProps = {
    className: "page-controls",
  };

  renderSlidesNumbers(currentSlideIndex) {
    const { slidesCount, scrollToSlide } = this.props;
    const slidesNames = isMobile
      ? [
          "Home",
          "About",
          "Projects",
          "",
          "",
          "",
          "",
          "",
          "Experience",
          "Contact",
        ]
      : ["Home", "About", "Projects", "", "", "Experience", "Contact"];
    const slidesToIgnore = isMobile ? [3, 4, 5, 6, 7] : [3, 4];
    const slidesNumbers = [];
    const closeEvent = new CustomEvent("closeMenu");
    for (let i = 0; i < slidesCount; i++) {
      if (slidesToIgnore.includes(i)) continue;

      const elemProps = {
        className: `link ${currentSlideIndex === i ? "active" : ""}`,
        key: i,
        onClick: () => {
          scrollToSlide(i);
          window.dispatchEvent(closeEvent);
        },
      };
      slidesNumbers.push(<button {...elemProps}>{slidesNames[i]}</button>);
    }
    return slidesNumbers;
  }

  render() {
    // const { getCurrentSlideIndex, slidesCount, className } = this.props;
    const { getCurrentSlideIndex } = this.props;
    const currentSlideIndex = getCurrentSlideIndex();

    return (
      <Navbar
        currentIndex={currentSlideIndex}
        buttons={this.renderSlidesNumbers(currentSlideIndex)}
      />
    );
  }
}

function Sections({ callFunc }) {
  const getProjectCard = (index) => {
    switch (index) {
      case 0:
        return (
          <ProjectCard
            github="https://github.com/TitoGrine/Ascoldata"
            website="https://www.ascoldata.fun"
            image={{
              src: ascoldata_img,
              alt: "Ascoldata Website",
            }}
            technologies={[
              ["javascript", "JavaScript"],
              ["react", "ReactJS"],
              ["firebase", "Firebase"],
              ["nodejs_small", "NodeJS"],
            ]}
          >
            <h4>Ascoldata Website</h4>
            <p>
              Website that allows users to see information about their music
              tastes, albums, artists, playlists, songs and even create
              playlists tailored to their tastes.
            </p>
            <p>
              Makes use of the <strong>Spotify API</strong> for most data,
              together with the Genius and Wikipedia API to provide even more
              information on songs and artists.
            </p>
          </ProjectCard>
        );

      case 1:
        return (
          <ProjectCard
            github="https://github.com/TitoGrine/AIAD_Project/tree/main/Project2"
            image={{
              src: evcs_img,
              alt: "Simulation Results",
            }}
            technologies={[
              ["java", "Java"],
              ["jade", "JADE"],
              ["repast", "Repast"],
            ]}
          >
            <h4>Electric Vehicle Charging Simulation</h4>
            <p>
              Simulation of a smart grid charging station capable of load
              balancing and peak shaving, using <strong>FIPA protocols</strong>{" "}
              to mimic different electric vehicles charging protocols.
            </p>
            <p>
              Uses JADE+Repast to simulate the different agents and gather data
              to compare the performance of diferent system characteristics.
            </p>
          </ProjectCard>
        );
      case 2:
        return (
          <ProjectCard
            github="https://github.com/TitoGrine/AIAD_Project/tree/main/Project2"
            image={{
              src: tweet_sa_img,
              alt: "Sentiment Analysis Results",
            }}
            technologies={[
              ["python", "Python"],
              ["jupyter", "Jupyter Notebook"],
            ]}
          >
            <h4>Tweet Sentiment Analysis</h4>
            <p>
              Categorization of tweets, from the SemEval2018 dataset, as
              positive, neutral or negative using{" "}
              <strong>Natural Language Processing</strong> methods.
            </p>
            <p>
              Pre-processes data and uses multiple classifiers from
              Scikit-Learn, including Naîves Bayes, Support Vector Classifier
              and Multi-Layered Perceptron.
            </p>
          </ProjectCard>
        );
      case 3:
        return (
          <ProjectCard
            github="https://github.com/TitoGrine/COMP_Project"
            image={{
              src: jmm_img,
              alt: "",
            }}
            technologies={[
              ["java", "Java"],
              ["jasmin", "Jasmin"],
            ]}
          >
            <h4>Java-- Compiler</h4>
            <p>
              Compiler for a simple language called Java-- with a parser for the
              grammar, together with lexical, syntatic and semantic analysis.
            </p>
            <p>
              Contains a translator to Java bytecodes with various optimization,
              in order for code written to be executable.
            </p>
          </ProjectCard>
        );
      case 4:
        return (
          <ProjectCard
            image={{
              src: air_meddigital_img,
              alt: "Air MedDigital Website",
            }}
            technologies={[
              ["react", "React"],
              ["nodejs_small", "NodeJS"],
              ["mongodb", "MongoDB"],
            ]}
          >
            <h4>Air MedDigital</h4>
            <p>
              Web application to simplify medical examinations of European
              flight professionals, and store all relevant medical
              documentation.
            </p>
            <p>
              Mainly worked on setting up{" "}
              <strong>Continuous Integration</strong>, building a backend API,{" "}
              <strong>unit</strong> and <strong>mutation testing</strong>.
            </p>
          </ProjectCard>
        );

      case 5:
        return (
          <ProjectCard
            image={{
              src: paintmix_img,
              alt: "Simulation Results",
            }}
            github="https://github.com/TitoGrine/LCOM_Project"
            technologies={[["c", "C Language"]]}
          >
            <h4>PaintMix</h4>
            <p>
              Low level painting game similar to pictionary designed for the
              MINIX operating system with custom designed graphics.
            </p>
            <p>
              The device drives for the keyboard, mouse and RTC where programmed
              from scratch.
            </p>
          </ProjectCard>
        );

      default:
        break;
    }
  };

  return (
    <>
      <FullPage
        id="sections"
        controls={CustomControls}
        scrollMode={isMobile ? "normal" : "full-page"}
      >
        <Slide className="component" id="component0">
          <Home />
        </Slide>
        <Slide className="component" id="component1">
          <About />
        </Slide>
        {!isMobile && (
          <Slide className="subcomponent" id="subcomponent0">
            {getProjectCard(0)}
            {getProjectCard(1)}
          </Slide>
        )}
        {!isMobile && (
          <Slide className="subcomponent" id="subcomponent1">
            {getProjectCard(2)}
            {getProjectCard(3)}
          </Slide>
        )}
        {!isMobile && (
          <Slide className="subcomponent" id="subcomponent2">
            {getProjectCard(4)}
            {getProjectCard(5)}
          </Slide>
        )}
        {isMobile &&
          [0, 1, 2, 3, 4, 5].map((value) => {
            return (
              <Slide
                key={value}
                className="subcomponent"
                id={`subcomponent${value}`}
              >
                {getProjectCard(value)}
              </Slide>
            );
          })}
        <Slide className="component" id="component3">
          <Experience />
        </Slide>
        <Slide className="component" id="component4">
          <Contact glitchFunc={callFunc} />
        </Slide>
      </FullPage>
    </>
  );
}

export default Sections;
