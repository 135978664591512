import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import ScrollReminder from "../ScrollReminder";

import "../../scss/home.scss";

function Home() {
  const [visibility, setVisibility] = useState(false);

  return (
    <div id="home">
      <ScrollTrigger
        onEnter={() => setVisibility(true)}
        onExit={() => setVisibility(false)}
        data-visible={visibility}
        id="name-title"
      >
        <strong>TITO</strong> GRINÉ
      </ScrollTrigger>
      <ScrollReminder text={""} />
    </div>
  );
}

export default Home;
