import React, { useState } from "react";
import Sound from "react-sound";

import "../scss/sound.scss";

import mp3 from "../audio/deep_ocean.mp3";

function AmbientSound() {
  const [play, setPlay] = useState(true);

  const getIcon = () => {
    return play ? (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.1733 18.2773H25.2983C24.8151 18.2773 24.4233 18.6691 24.4233 19.1523V29.6523C24.4233 30.1356 24.8151 30.5273 25.2983 30.5273H26.1733C27.1399 30.5273 27.9233 29.7439 27.9233 28.7773V20.0273C27.9233 19.0608 27.1399 18.2773 26.1733 18.2773Z"
          fill="white"
        />
        <path
          d="M11.1812 18.2773H10.3062C9.33963 18.2773 8.55615 19.0608 8.55615 20.0273V28.7773C8.55615 29.7439 9.33963 30.5273 10.3062 30.5273H11.1812C11.6644 30.5273 12.0562 30.1356 12.0562 29.6523V19.1523C12.0562 18.6691 11.6644 18.2773 11.1812 18.2773Z"
          fill="white"
        />
        <path
          d="M18.2395 4.27734C10.0372 4.27734 3.30615 10.9501 3.30615 19.1523V26.1523C3.30615 27.5998 4.48367 28.7773 5.93115 28.7773H6.80615V20.0273H5.93115C5.62292 20.0273 5.33119 20.0905 5.05615 20.1885V19.1523C5.05615 11.9148 11.002 6.02734 18.2395 6.02734C25.477 6.02734 31.4228 11.9148 31.4228 19.1523V20.1885C31.1478 20.0905 30.8561 20.0273 30.5478 20.0273H29.6728V28.7773H30.5478C31.9953 28.7773 33.1728 27.5998 33.1728 26.1523V19.1523C33.1728 10.9501 26.4417 4.27734 18.2395 4.27734Z"
          fill="white"
        />
      </svg>
    ) : (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.7988 18.2773H24.9238C24.4406 18.2773 24.0488 18.6691 24.0488 19.1523V29.6523C24.0488 30.1356 24.4406 30.5273 24.9238 30.5273H25.7988C26.7654 30.5273 27.5488 29.7439 27.5488 28.7773V20.0273C27.5488 19.0608 26.7654 18.2773 25.7988 18.2773Z"
          fill="white"
        />
        <path
          d="M10.8066 18.2773H9.93164C8.96512 18.2773 8.18164 19.0608 8.18164 20.0273V28.7773C8.18164 29.7439 8.96512 30.5273 9.93164 30.5273H10.8066C11.2899 30.5273 11.6816 30.1356 11.6816 29.6523V19.1523C11.6816 18.6691 11.2899 18.2773 10.8066 18.2773Z"
          fill="white"
        />
        <path
          d="M17.865 4.27734C9.66272 4.27734 2.93164 10.9501 2.93164 19.1523V26.1523C2.93164 27.5998 4.10916 28.7773 5.55664 28.7773H6.43164V20.0273H5.55664C5.24841 20.0273 4.95668 20.0905 4.68164 20.1885V19.1523C4.68164 11.9148 10.6274 6.02734 17.865 6.02734C25.1025 6.02734 31.0483 11.9148 31.0483 19.1523V20.1885C30.7733 20.0905 30.4815 20.0273 30.1733 20.0273H29.2983V28.7773H30.1733C31.6208 28.7773 32.7983 27.5998 32.7983 26.1523V19.1523C32.7983 10.9501 26.0672 4.27734 17.865 4.27734Z"
          fill="white"
        />
        <rect
          x="31.3301"
          y="3.26172"
          width="1.83497"
          height="39.9211"
          rx="0.917484"
          transform="rotate(45 31.3301 3.26172)"
          fill="white"
        />
      </svg>
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    setPlay(!play);
  };

  return (
    <button id="sound-icons" onClick={handleClick}>
      {getIcon()}
      <Sound
        url={mp3}
        playStatus={play ? Sound.status.PLAYING : Sound.status.PAUSED}
        loop={true}
      />
    </button>
  );
}

export default AmbientSound;
