import React, { Suspense, useEffect, useMemo, useRef } from "react";

import * as THREE from "three";
import { Canvas, extend, useFrame, useThree } from "react-three-fiber";
import { Effects, PerspectiveCamera, useGLTF } from "@react-three/drei";
import { GlitchPass } from "./Glitchpass";
import { WaterPass } from "./Waterpass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import Particles from "./Particles";
import "../../scss/background.scss";
import { isFirefox, isMobile } from "react-device-detect";

// Makes these prototypes available as "native" jsx-string elements
extend({ WaterPass, GlitchPass, UnrealBloomPass, FilmPass });

const Model = ({ toggle, mode }) => {
  const path2Model = `/models/${
    mode ? `${isFirefox ? "firefox_" : ""}scene` : "filler"
  }.gltf`;

  const gltf = useGLTF(path2Model, true);
  const ref = useRef(null);

  const model = useMemo(
    () =>
      mode ? (
        <mesh>
          <primitive
            object={gltf.scene}
            position={isFirefox ? [-1, -2, 2] : [-6, 0, 4]}
            scale={isFirefox ? [1, 1, 1] : [0.01, 0.01, 0.01]}
            rotation={
              isFirefox ? [-Math.PI / 6, -Math.PI / 6, -Math.PI / 6] : [0, 0, 0]
            }
            dispose={null}
          />
        </mesh>
      ) : (
        <mesh pos={[0, 0, 11]} ref={ref}>
          <sphereGeometry args={[0.02, 60, 60]} />
          <meshPhongMaterial color="#fffed4" />
        </mesh>
      ),
    [gltf.scene, mode]
  );

  // Update it every frame
  useFrame(() => {
    if (!isFirefox) ref.current.rotation.y += 0.007;
  });

  return (
    <>
      <group ref={ref} position={[0, 0, 0]}>
        {model}
      </group>
      <Effect toggle={toggle} />
    </>
  );
};

function Effect({ toggle }) {
  const { size } = useThree();

  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [
    size,
  ]);

  return (
    <Effects>
      <unrealBloomPass attachArray="passes" args={[aspect, 0.6, 0.5, 0]} />
      <filmPass attachArray="passes" args={[0.25, 0.4, 1500, false]} />
      <waterPass attachArray="passes" factor={1} />
      <glitchPass attachArray="passes" factor={toggle ? 1.5 : 0} />
    </Effects>
  );
}

function CursorParticle({ mouse, camera }) {
  const ref = useRef(null);
  var vec = new THREE.Vector3(); // create once and reuse
  var pos = new THREE.Vector3(); // create once and reuse

  useFrame(() => {
    if (camera.current === undefined) return;

    vec.set(mouse.x, mouse.y, 0);

    vec.unproject(camera.current);

    vec.sub(camera.current.position).normalize();

    var distance = (11 - camera.current.position.z) / vec.z;

    pos.copy(camera.current.position).add(vec.multiplyScalar(distance));

    ref.current.position.set(pos.x, pos.y, pos.z);
  });

  return (
    <mesh pos={[0, 0, 11]} ref={ref}>
      <sphereGeometry args={[0.02, 60, 60]} />
      <meshPhongMaterial color="#fffed4" />
    </mesh>
  );
}

function Background({ glitch, cursorVec, setProgress, setFinished, mode }) {
  const camera = useRef(null);

  useEffect(() => {
    THREE.DefaultLoadingManager.onLoad = () =>
      setTimeout(() => setFinished(true), 1500);
    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, total) =>
      setProgress((100 * (itemsLoaded / total)).toFixed(2));
  }, [setProgress, setFinished]);

  return (
    <div id="scene">
      <Canvas
        colorManagement
        onCreated={({ gl }) => {
          gl.toneMappingExposure = 0.3;
          gl.physicallyCorrectLights = true;
        }}
      >
        <Suspense fallback={null}>
          <PerspectiveCamera
            makeDefault
            position={[0, 0, 12]}
            fov={80}
            ref={camera}
          />
          <ambientLight intensity={0.05} color="#ccfafc" />
          <directionalLight
            color="#5ce6ba"
            position={[0, 30, 0]}
            intensity={isFirefox ? 1.5 : 0.3}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            shadow-camera-far={100}
            shadow-camera-right={-10}
            shadow-camera-left={10}
            shadow-camera-top={10}
            shadow-camera-bottom={-10}
          />
          <pointLight
            position={[5, 30, 0]}
            color="#ffecbd"
            intensity={isFirefox ? 3 : 3}
          />
          <pointLight
            position={[0, 30, 10]}
            color="#dddbff"
            intensity={isFirefox ? 2 : 2}
          />
          <pointLight
            position={[0, -25, 0]}
            color="#0073c4"
            intensity={isFirefox ? 6 : 2}
          />

          {!isMobile && <CursorParticle mouse={cursorVec} camera={camera} />}

          <Particles count={isMobile || isFirefox ? 1000 : 5000} />

          <Model toggle={glitch} mode={mode} />
        </Suspense>
      </Canvas>
    </div>
  );
}

export default Background;
