import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

function Navbar({ currentIndex, buttons }) {
  const [toggle, setToggle] = useState(false);
  const GitHubLink = "https://github.com/TitoGrine";
  const LinkedInLink = "https://www.linkedin.com/in/titogrine";
  const EmailLink = "mailto:tito999@gmail.com";
  const CVLink = "/files/CV.pdf";

  useEffect(() => {
    window.addEventListener("closeMenu", () =>
      setTimeout(() => setToggle(false), 50)
    );
  }, []);

  return (
    <div className="page-controls">
      <svg
        width="75"
        height="75"
        viewBox="0 0 75 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="hamburguer"
        data-close={toggle ? "yes" : "no"}
        onClick={() => setToggle(!toggle)}
      >
        <path
          d="M60.8524 28.5762L14.8677 28.5762L18.7526 32.4611L56.9674 32.4611L60.8524 28.5762Z"
          fill="white"
        />
        <path
          d="M60.8524 46.8506L14.8677 46.8506L18.7526 42.9656L56.9674 42.9656L60.8524 46.8506Z"
          fill="white"
        />
      </svg>

      <CSSTransition
        in={toggle}
        timeout={500}
        unmountOnExit
        classNames="menu"
        className="menu"
      >
        <div>
          {buttons}
          <a
            id="curriculum"
            href={CVLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Curriculum Vitae
          </a>
          <section id="socials">
            <a href={GitHubLink} target="_blank" rel="noopener noreferrer">
              <svg
                width="35"
                height="36"
                viewBox="0 0 35 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 0.98842C35 0.98842 0.268677 0.988415 2.53329e-06 0.988403L0 35.9884H11.9407V35.9805C12.4385 35.9748 12.8199 35.6984 12.8374 35.2308C12.8826 34.0155 12.8507 32.7975 12.8507 31.6225C11.9624 31.6636 11.1143 31.7748 10.2759 31.7269C8.42654 31.6213 7.00548 30.8388 6.26848 29.0432C5.8319 27.9794 5.17406 27.0695 4.14955 26.4623C3.95823 26.3488 3.79096 26.1817 3.64457 26.012C3.45325 25.7907 3.48904 25.5633 3.79133 25.5026C4.0963 25.4411 4.42948 25.3981 4.73022 25.4539C5.91138 25.6738 6.72816 26.4083 7.35338 27.3854C8.58966 29.3183 10.4926 29.91 12.6335 29.0174C12.7529 28.9676 12.9046 28.847 12.9271 28.7369C13.0997 27.8812 13.4222 27.101 14.0721 26.417C13.3496 26.2843 12.6803 26.1866 12.024 26.0357C10.1339 25.6021 8.43605 24.8219 7.19444 23.2724C6.18142 22.0084 5.71819 20.5288 5.50525 18.954C5.17176 16.4874 5.47027 14.1785 7.17847 12.2075C7.26478 12.1079 7.31157 11.9039 7.27317 11.7798C6.84535 10.3937 6.91072 9.01914 7.33474 7.64843C7.55725 6.92857 7.6192 6.89271 8.36 6.99302C8.40529 6.99906 8.44935 7.01034 8.49427 7.01791C9.97922 7.26154 11.2574 7.97456 12.5164 8.74008C12.6857 8.84304 12.9435 8.90712 13.1295 8.86226C16.1367 8.13864 19.1382 8.14166 22.1451 8.86491C22.3196 8.90675 22.5528 8.84717 22.7197 8.76047C23.4042 8.40563 24.0594 7.99311 24.7527 7.65669C25.5592 7.26567 26.4087 6.97152 27.3233 6.98772C27.4834 6.99074 27.731 7.14232 27.7846 7.28262C28.3348 8.72837 28.4509 10.2017 28.0124 11.7063C27.9656 11.8669 28.0352 12.0958 28.1223 12.2542C28.5551 13.0415 29.1593 13.7682 29.4331 14.6034C30.2679 17.1491 29.9636 19.67 28.8273 22.0626C27.7861 24.2558 25.8551 25.3671 23.5693 25.9403C22.7973 26.1338 22.0063 26.2514 21.1735 26.4136C22.2649 27.5441 22.436 28.9238 22.4166 30.3688C22.3965 31.8594 22.4018 33.3505 22.401 34.8415C22.4007 35.5636 22.7186 35.9626 23.2734 35.9859C23.273 35.9882 35 35.9882 35 35.9882V0.98842Z"
                  fill="black"
                />
              </svg>
            </a>
            <a href={LinkedInLink} target="_blank" rel="noopener noreferrer">
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0V35H35V0H0ZM11.7502 27.801H7.64364V13.4657H11.75V27.801H11.7502ZM9.73744 11.8309C8.36004 11.8309 7.23951 10.7919 7.23951 9.51489C7.23951 8.23787 8.36018 7.19884 9.73744 7.19884C11.1147 7.19884 12.2354 8.23787 12.2354 9.51489C12.2354 10.7919 11.1147 11.8309 9.73744 11.8309ZM27.7605 27.801H23.553V19.2992C23.5539 19.2819 23.6153 17.9322 22.85 17.1259C22.4691 16.7245 21.9444 16.521 21.2903 16.521C19.6449 16.521 18.8139 17.7057 18.4617 18.4233V27.801H14.3551V13.4657H18.4615V14.6768C19.8057 13.5862 21.3154 13.0109 22.8367 13.0109C26.7314 13.0109 27.7605 16.0532 27.7605 17.6618V27.801Z"
                  fill="black"
                />
              </svg>
            </a>
            <a href={EmailLink}>
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9954 17.6168L10.9348 11.5648H25.0559L17.9954 17.6168Z"
                  fill="black"
                />
                <path
                  d="M26.1204 12.2985L18.4021 18.9141C18.2888 19.0114 18.1445 19.0648 17.9954 19.0648C17.8462 19.0648 17.7019 19.0114 17.5886 18.9141L9.87036 12.2985V24.0648H26.1204V12.2985Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M35.4954 0.314819V35.3148H0.495361V0.314819H35.4954ZM26.7454 25.3148C27.0906 25.3148 27.3704 25.035 27.3704 24.6898V10.9398C27.3704 10.5947 27.0906 10.3148 26.7454 10.3148H9.24536C8.90015 10.3148 8.62036 10.5947 8.62036 10.9398V24.6898C8.62036 25.035 8.90015 25.3148 9.24536 25.3148H26.7454Z"
                  fill="black"
                />
              </svg>
            </a>
          </section>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Navbar;
