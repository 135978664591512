import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";

import "../../scss/experience.scss";

function Experience() {
  const [visibility, setVisibility] = useState([false, false, false]);

  const changeVisibility = (index, value) => {
    if (index >= 0 && index < 4)
      setVisibility(visibility.map((v, i) => (index === i ? value : v)));
  };

  return (
    <div id="experience">
      <ScrollTrigger
        onEnter={() => changeVisibility(0, true)}
        onExit={() => changeVisibility(0, false)}
      >
        <p data-visible={visibility[0]}>
          Unfortunately I haven't had <span id="empty">any</span> professional
          experience...
        </p>
      </ScrollTrigger>
      <ScrollTrigger
        onEnter={() => changeVisibility(1, true)}
        onExit={() => changeVisibility(1, false)}
      >
        <p data-visible={visibility[1]}>
          Maybe <span id="you">you</span> could help me out?
        </p>
      </ScrollTrigger>
      <ScrollTrigger
        onEnter={() => changeVisibility(2, true)}
        onExit={() => changeVisibility(2, false)}
      >
        <p data-visible={visibility[2]}>
          If you think I would be a good asset to your team or project, then
          please, feel free to <span id="contact-me">contact me</span>!
        </p>
      </ScrollTrigger>
    </div>
  );
}

export default Experience;
