import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

import "../../scss/contact.scss";
import { isFirefox, isMobile } from "react-device-detect";

function Contact({ glitchFunc }) {
  const [valid, setValid] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!valid) {
      glitchFunc();
      return;
    }

    const templateParams = {
      name: e.target[0].value,
      subject: e.target[1].value,
      sender_email: e.target[2].value,
      message: e.target[3].value,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        function (response) {
          alert("Email has been sent successfully! Thank you!");
          formRef.current.reset();
        },
        function (err) {
          glitchFunc();
          alert(
            "Oh no... something went wrong. \nPlease contact me directly at tito999@gmail.com"
          );
        }
      );
  };

  return (
    <>
      <div id="contact">
        <ScrollTrigger
          onEnter={() => setVisibility(true)}
          onExit={() => setVisibility(false)}
          data-visible={visibility}
        >
          <form autoComplete="true" onSubmit={handleSubmit} ref={formRef}>
            <p>Get in touch</p>
            <div>
              <label htmlFor="name">Name </label>
              <input type="text" name="name" id="name" required />
            </div>
            <div>
              <label htmlFor="subject">Subject </label>
              <input type="text" name="subject" id="subject" required />
            </div>
            <div>
              <label htmlFor="email">Email </label>
              <input type="email" name="email" id="email" required />
            </div>
            <div>
              <label htmlFor="message">Message </label>
              <textarea
                rows={isMobile ? 1 : 3}
                name="message"
                id="message"
                required
              />
            </div>
            <div id="captcha">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(value) => setValid(value)}
                size={isMobile ? "compact" : "normal"}
              />
            </div>
            <div id="submit-button">
              <button type="submit">SEND</button>
            </div>
          </form>
        </ScrollTrigger>
        <footer>
          <p> Tito Griné · 2021</p>
          <p>
            Special thanks to{" "}
            <a
              href={`https://${
                isFirefox ? "lalobravo" : "alexdubnoff"
              }.artstation.com/`}
              target="_blank"
              rel="noreferrer"
            >
              {isFirefox ? "Lalo Bravo" : "Alex Dubnoff"}
            </a>{" "}
            for making the 3D model!
          </p>
        </footer>
      </div>
    </>
  );
}

export default Contact;
