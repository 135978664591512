import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";

import "../../scss/about.scss";

function About() {
  const [visibility, setVisibility] = useState([false, false, false]);

  const changeVisibility = (index, value) => {
    if (index >= 0 && index < 4)
      setVisibility(visibility.map((v, i) => (index === i ? value : v)));
  };

  return (
    <div id="about">
      <ScrollTrigger
        onEnter={() => changeVisibility(0, true)}
        onExit={() => changeVisibility(0, false)}
      >
        <p data-visible={visibility[0]}>
          I'm a master's student in <span id="cs">computer science</span> and{" "}
          <span id="se">software engineering</span> from{" "}
          <span id="pt">Portugal</span>.
        </p>
      </ScrollTrigger>
      <ScrollTrigger
        onEnter={() => changeVisibility(1, true)}
        onExit={() => changeVisibility(1, false)}
      >
        <p data-visible={visibility[1]}>
          My interests range from
          <span id="db">
            <span>d</span>
            <span>a</span>
            <span>t</span>
            <span>a</span>
            <span>b</span>
            <span>a</span>
            <span>s</span>
            <span>e</span>
            <span>s</span>
          </span>
          , <span id="bd">big data</span> to <span id="dg">design</span>,
          <span className="w">web</span>
          <span className="d">development</span> and{" "}
          <span className="dv">
            <span>d</span>
            <span>a</span>
            <span>t</span>
            <span>a</span>
          </span>
          <span className="dv">
            <span>v</span>
            <span>i</span>
            <span>s</span>
            <span>u</span>
            <span>a</span>
            <span>l</span>
            <span>i</span>
            <span>z</span>
            <span>a</span>
            <span>t</span>
            <span>i</span>
            <span>o</span>
            <span>n</span>
          </span>
          ...
        </p>
      </ScrollTrigger>
      <ScrollTrigger
        onEnter={() => changeVisibility(2, true)}
        onExit={() => changeVisibility(2, false)}
      >
        <p data-visible={visibility[2]}>
          ...but I'm always down to try something{" "}
          <span id="new">
            <span>n</span>
            <span>e</span>
            <span>w</span>
          </span>
          !
        </p>
      </ScrollTrigger>
    </div>
  );
}

export default About;
