import React, { useEffect, useState } from "react";

import "../scss/loading.scss";

function Loading({ progress, finished }) {
  const [active, setActive] = useState(0);

  const messages = [
    "Loading some cool stuff...",
    "^⨀ᴥ⨀^",
    "Thanks for waiting...",
    "Almost there...",
  ];

  const fillPercentage = {
    width: progress + "%",
  };

  useEffect(() => {
    setTimeout(() => setActive(1), 10000);
    setTimeout(() => setActive(2), 25000);
    setTimeout(() => setActive(3), 50000);
  }, []);

  const getMessages = () => {
    return messages.map((message, index) => {
      return (
        <div
          key={index}
          style={{ display: index === active ? "inline-block" : "none" }}
        >
          {Array.from(message).map((letter, i) => {
            return letter.localeCompare(" ") === 1 ? (
              <span key={i}>{letter}</span>
            ) : (
              <div key={i}> </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div className="loading-screen">
      <h3>{getMessages()}</h3>
      <div id="progress-bar">
        <div id="filled" style={fillPercentage} />
      </div>
    </div>
  );
}

export default Loading;
