import React, { useEffect, useState } from "react";

import Background from "./components/Background/Background";
import Sections from "./components/Sections/Sections";
import Loading from "./components/Loading";
import * as THREE from "three";
import SelectOptimal from "./components/SelectOptimal";
import AmbientSound from "./components/AmbientSound";

function App() {
  const [mode, setMode] = useState(-1);
  const [glitch, setGlitch] = useState(false);
  const [progress, setProgress] = useState(0);
  const [finished, setFinished] = useState(false);
  const [cursorVec, setCursorVec] = useState(new THREE.Vector2(0, 0));

  const triggerGlitch = () => {
    setGlitch(true);
  };

  const updateCursor = (e) => {
    setCursorVec(
      new THREE.Vector3(
        (e.clientX / window.innerWidth) * 2 - 1,
        -(e.clientY / window.innerHeight) * 2 + 1
      )
    );
  };

  useEffect(() => {
    if (glitch) setTimeout(() => setGlitch(false), 700);
  }, [glitch]);

  return (
    <div className="App">
      <main onMouseMove={updateCursor}>
        {mode < 0 && <SelectOptimal setMode={setMode} />}
        {mode >= 0 && (
          <>
            <Background
              glitch={glitch}
              cursorVec={cursorVec}
              setProgress={setProgress}
              setFinished={setFinished}
              mode={mode}
            />
            {finished && (
              <>
                <Sections callFunc={triggerGlitch} />
                <AmbientSound />
              </>
            )}
            <Loading finished={finished} progress={progress} />
          </>
        )}
      </main>
    </div>
  );
}

export default App;
