import React from "react";

function ScrollReminder({ text }) {
  return (
    <div id="scroll-reminder">
      <p>{text}</p>
      <svg
        width="85"
        height="27"
        viewBox="0 0 301 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.517944 0.35553L150.518 60.4547L300.518 0.35553H291.787L150.518 56.9567L9.24871 0.35553H0.517944Z"
          fill="white"
        />
        <path
          d="M0.517944 30.2563L150.518 90.3555L300.518 30.2563H291.787L150.518 86.8574L9.24871 30.2563H0.517944Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default ScrollReminder;
