import React from "react";

function SelectOptimal({ setMode }) {
  return (
    <div className="selection-screen">
      <h3>Render 3D Model?</h3>
      <section id="mode-buttons">
        <button onClick={() => setMode(1)}>Yes</button>
        <button onClick={() => setMode(0)}>No</button>
      </section>
      <p>
        This website makes use of a complex 3D model that, on weaker connections
        can take long to load and on slower devices can significantly affect
        performance and render the website unusable.
        <br />
        So, if you wish to avoid this and <strong>not</strong> render the 3D
        model, select <strong>NO</strong>.
      </p>
    </div>
  );
}

export default SelectOptimal;
